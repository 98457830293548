import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { FEDERAL_AGENCIES } from '../../utils/constants/Constants';
import { realtimeSearchOfficesForAgency } from '../../networking/SearchCalls';

const AgencyAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubAgencies, setFilteredSubAgencies] = useState([]);
  const [selectedSubAgency, setSelectedSubAgency] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [filteredOffices, setFilteredOffices] = useState([]);
  const [addedAgencies, setAddedAgencies] = useState([]); // Track multiple added agencies/offices
  const [showOffices, setShowOffices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  // Clear everything when keywords are cleared
  useEffect(() => {
    if (keywords.length === 0) {
      resetSelections();
    }
  }, [keywords]);

  // Expose reset functionality to the parent component via ref
  useImperativeHandle(ref, () => ({
    resetSelectedAgencies: () => {
      setAddedAgencies([]);
      resetSelections(); // Clear selected agency and offices
    },
  }));

  // Filter subagencies based on search term
  useEffect(() => {
    if (searchTerm && !showOffices) {
      const filtered = FEDERAL_AGENCIES.flatMap((agency) =>
        agency.subAgencies
          .filter((sub) =>
            sub.parent.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((sub) => ({ parent: agency.parent, subagency: sub.parent }))
      );
      setFilteredSubAgencies(filtered);
    } else {
      setFilteredSubAgencies([]);
    }
  }, [searchTerm, showOffices]);

  // Query Firestore for offices on text change if 3 or more characters are entered
  useEffect(() => {
    if (showOffices && searchTerm.length >= 3) {
      setLoading(true); // Start loading
      realtimeSearchOfficesForAgency(searchTerm)
        .then((data) => {
          setOffices(data);
          setFilteredOffices(data); // Show all matching offices
        })
        .catch((error) => console.error('Error fetching offices:', error))
        .finally(() => setLoading(false)); // Stop loading
    } else {
      setFilteredOffices([]); // Clear filtered offices if fewer than 3 characters
    }
  }, [searchTerm, showOffices]);

  // Clear search term
  const clearSearch = () => {
    setSearchTerm('');
    setFilteredSubAgencies([]);
    setFilteredOffices([]);
  };

  // Helper function to split and add keywords
  const addKeywords = (text) => {
    const splitKeywords = text.toLowerCase().split(/\s+/); // Lowercase and split by spaces
    setKeywords((prev) => [...prev, ...splitKeywords]);
  };

  // Add agency or office to the list and update the count
  const addAgency = (agencyLabel) => {
    setAddedAgencies((prev) => [...prev, agencyLabel]); // Add the new agency/office to the list
  };

  // Handle selection of a subagency
  const handleSelectSubAgency = (agency) => {
    setSelectedSubAgency(agency);
    setSearchTerm('');
    setShowOffices(true);
    setButtonsVisible(true); // Show Continue Without Office button
  };

  // Handle selection of an office
  const handleSelectOffice = (office) => {
    const agencyLabel = `${selectedSubAgency.parent} / ${selectedSubAgency.subagency} / ${office.name}`;
    addKeywords(office.name);
    addKeywords(selectedSubAgency.subagency);
    addAgency(agencyLabel); // Add formatted agency/office string
    resetSelections(); // Reset after adding
  };

  // Handle adding subagency without office
  const handleAddSubAgencyWithoutOffice = () => {
    const agencyLabel = `${selectedSubAgency.parent} / ${selectedSubAgency.subagency}`;
    addKeywords(selectedSubAgency.subagency);
    addAgency(agencyLabel); // Add subagency only
    resetSelections(); // Reset after adding
  };

  // Reset selections
  const resetSelections = () => {
    setSelectedSubAgency(null);
    setSelectedOffice(null);
    setSearchTerm('');
    setShowOffices(false);
    setButtonsVisible(false);
  };

  // Handle removing an agency from the list
  const handleRemoveAgency = (index) => {
    const updatedAgencies = addedAgencies.filter((_, i) => i !== index); // Remove by index
    setAddedAgencies(updatedAgencies);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        {/* Badge with Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: addedAgencies.length === 0 ? 'gray' : '#00274D', // Gray background if 0
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {addedAgencies.length}
          </Typography>
        </Box>
        <Typography>Agency</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Bar with Clear Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label={showOffices ? 'Search Offices' : 'Search Subagencies'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton onClick={clearSearch} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>

        {/* Display Selected Agency / Subagency for reference */}
        {selectedSubAgency && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="body2">
              {`${selectedSubAgency.parent} / ${selectedSubAgency.subagency}`}
            </Typography>
          </Box>
        )}

        {/* Display List of Added Agencies */}
        {addedAgencies.map((agency, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="body2">{agency}</Typography>
            <IconButton onClick={() => handleRemoveAgency(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}

        {/* Vertically stacked buttons without outline */}
        {buttonsVisible && (
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button variant="contained" onClick={handleAddSubAgencyWithoutOffice}>
              Continue Without Office
            </Button>
          </Box>
        )}

        {/* Show loading spinner while fetching offices */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50px' }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
            {showOffices ? (
              // Display filtered offices with scrolling window
              filteredOffices.map((office, index) => (
                <Typography key={index} onClick={() => handleSelectOffice(office)}>
                  {office.name} - {office.code}
                </Typography>
              ))
            ) : (
              // Display filtered subagencies
              filteredSubAgencies.map((agency, index) => (
                <Typography key={index} onClick={() => handleSelectSubAgency(agency)}>
                  {`${agency.parent} / ${agency.subagency}`}
                </Typography>
              ))
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
});

export default AgencyAccordion;