import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Paper, RadioGroup, FormControlLabel, Radio, AppBar, Toolbar, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { getSubscriptionDetails, changeSubscription } from '../networking/AccountCalls';

const MyAccountDialog = ({ open, onClose }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // Fetch subscription details when dialog opens
  useEffect(() => {
    if (open && currentUser) {
      fetchSubscriptionDetails();
    }
  }, [open, currentUser]);

  // Fetch subscription details
  const fetchSubscriptionDetails = async () => {
    try {
      setLoading(true);
      const data = await getSubscriptionDetails(currentUser.uid);
      setSubscriptionData(data);

      // Set selectedPriceId to null if there's no current subscription
      if (data.currentPriceID) {
        setSelectedPriceId(data.currentPriceID);
      } else {
        setSelectedPriceId(null); // No radios selected if there's no subscription
      }
    } catch (error) {
      console.error("Error fetching subscription details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle updating the subscription
  const handleUpdateSubscription = async () => {
    setConfirmationOpen(false); // Close the confirmation dialog
    try {
      await changeSubscription(currentUser.uid, selectedPriceId); // Call the cloud function
      alert("Subscription updated successfully.");
      onClose(); // Close the dialog
    } catch (error) {
      console.error("Error updating subscription:", error);
      alert("Failed to update subscription.");
    }
  };

  // Open confirmation dialog
  const handleConfirmUpdate = () => {
    setConfirmationOpen(true);
  };

  // Handle closing confirmation dialog
  const handleCancelUpdate = () => {
    setConfirmationOpen(false);
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogContent sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography>Loading subscription details...</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (!subscriptionData) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>Failed to retrieve subscription details.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const { currentProductName, currentPriceAmount, currentPriceID, availableProducts } = subscriptionData;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth sx={{ maxHeight: '80vh' }}>
      {/* Navy Nav Bar */}
      <AppBar position="static" sx={{ backgroundColor: '#00274D' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}>
            Account: {currentUser.email}
          </Typography>
          <Button onClick={onClose} sx={{ color: 'white', marginRight: 2 }}>
            Keep Current Subscription
          </Button>
          <Button onClick={handleConfirmUpdate} sx={{ color: 'white' }}>
            Update Subscription
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {/* Current Plan */}
        <Paper sx={{ padding: 2, marginBottom: 3 }}>
          <Typography variant="h6" component="span" fontWeight="bold">
            Current Plan:
          </Typography>
          <Typography component="span" sx={{ marginLeft: 2 }}>
            {currentProductName
              ? `${currentProductName} - $${(currentPriceAmount / 100).toFixed(2)} per ${
                  availableProducts
                    .find((product) => product.prices.some((price) => price.priceId === currentPriceID))
                    ?.prices.find((price) => price.priceId === currentPriceID).recurring
                }`
              : 'No Current Subscription'}
          </Typography>
        </Paper>

        {/* Other Products (3 Papers Side by Side) */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {availableProducts.map((product) => (
            <Paper key={product.productId} sx={{ width: '32%', padding: 2 }}>
              <Typography variant="h6">{product.productName}</Typography>
              <RadioGroup
                value={selectedPriceId}
                onChange={(e) => setSelectedPriceId(e.target.value)}
              >
                {product.prices.map((price) => (
                  <FormControlLabel
                    key={price.priceId}
                    value={price.priceId}
                    control={<Radio />}
                    label={`$${(price.unit_amount / 100).toFixed(2)} per ${price.recurring}`}
                  />
                ))}
              </RadioGroup>
            </Paper>
          ))}
        </Box>
      </DialogContent>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleCancelUpdate}>
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update your subscription?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateSubscription} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default MyAccountDialog;