import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure Firebase is properly set up in this file

// Fetch user profile data from Firestore
export const getUserProfile = async (userID) => {
    try {
        if (typeof userID !== 'string' || !userID) {
            throw new Error('Invalid user ID. It must be a non-empty string.');
        }

        const userDocRef = doc(db, 'user', userID); // Ensure userID is a string
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            return userDoc.data();
        } else {
            console.log("No user document found!");
            return null;
        }
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const fetchSolicitationByNoticeId = async (noticeId) => {
    try {
      const docRef = doc(db, 'solicitation', noticeId); // Replace 'solicitations' with the name of your collection
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return docSnap.data(); // Document data
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      throw error;
    }
  };

// Helper function to calculate the timestamp for two days ago
const getTwoDaysAgoTimestamp = () => {
    const now = new Date();
    const twoDaysAgo = new Date(now.setDate(now.getDate() - 2));
    return twoDaysAgo.getTime();  // Get timestamp in milliseconds
};

// Function to fetch the number of solicitations for each saved search term
export const getSavedOrRecentSearches = async (searchTerms) => {
    const twoDaysAgoTimestamp = getTwoDaysAgoTimestamp();
    const result = [];

    // Loop through each search term and query Firestore for each one
    for (const term of searchTerms) {
        const isQuoted = /^".+"$/.test(term);  // Check if the term is surrounded by double quotes
        const cleanTerm = term.replace(/"/g, '');  // Remove the double quotes
        const splitTerms = cleanTerm.split(/\s+/);  // Split the term by space

        try {
            // Step 1: Perform the query using "array-contains-any"
            const solicitationQuery = query(
                collection(db, 'solicitation'),
                where('timestamp', '>=', twoDaysAgoTimestamp),  // Solicitations within the last two days
                where('searchTerms', 'array-contains-any', splitTerms.slice(0, 10))  // Matches any of the split terms (up to 10 terms)
            );

            const querySnapshot = await getDocs(solicitationQuery);

            // Step 2: Process the results
            let matchingCount = 0;
            
            if (isQuoted) {
                // If the term is quoted, filter the results by checking specific fields
                querySnapshot.forEach((doc) => {
                    const data = doc.data();

                    // Check if the un-split term exists in any of the following fields
                    const fieldsToCheck = ['title', 'description', 'contractingOffice', 'baseType', 'naicsCode'];
                    const isMatch = fieldsToCheck.some((field) => 
                        data[field] && data[field].toString().toLowerCase().includes(cleanTerm.toLowerCase())
                    );

                    if (isMatch) {
                        matchingCount++;
                    }
                });
            } else {
                // If the term is not quoted, simply count the results
                matchingCount = querySnapshot.size;
            }

            // Step 3: Store the result for the current term
            result.push({
                name: term,  // Original term before splitting or removing quotes
                count: matchingCount  // Number of matching documents
            });
        } catch (error) {
            console.error(`Error fetching saved searches for term "${term}":`, error);
            result.push({
                name: term,
                count: 0  // In case of error, return 0 count
            });
        }
    }

    return result;  // Return the array of results
};


// Fetch tracked solicitations for the user, excluding archived solicitations
export const getTrackedSolicitations = async (trackedSolicitations, userID, archivedSols) => {

    if (trackedSolicitations.length === 0) {
        console.log("No tracked solicitations found.");
        return [];
    }

    // Query for details of tracked solicitations in the solicitation collection
    try {
        const solQuery = query(collection(db, 'solicitation'), where('solicitationNumber', 'in', trackedSolicitations));
        const querySnapshot = await getDocs(solQuery);

        const solicitationMap = {};

        querySnapshot.forEach((doc) => {
            let solicitation = doc.data();

            // Format and clean up solicitation data
            if (solicitation.baseType) solicitation.baseType = toProperCaps(solicitation.baseType);
            if (solicitation.contractingOffice) solicitation.contractingOffice = toProperCaps(solicitation.contractingOffice);
            if (solicitation.title) solicitation.title = toProperCaps(solicitation.title);
            if (solicitation.type) solicitation.type = toProperCaps(solicitation.type);
            if (solicitation.description) solicitation.description = toProperCaps(solicitation.description);
            if (solicitation.performanceCity) solicitation.performanceCity = toProperCaps(solicitation.performanceCity);
            if (solicitation.performanceCountry) solicitation.performanceCountry = toProperCaps(solicitation.performanceCountry);
            if (solicitation.performanceStreetAddress) solicitation.performanceStreetAddress = toProperCaps(solicitation.performanceStreetAddress);
            if (solicitation.setAsideDescription) solicitation.setAsideDescription = toProperCaps(solicitation.setAsideDescription);

            solicitation.isSaved = true;

            // Compare the postedDate to keep the most recent solicitation for each solicitationNumber
            const solicitationNumber = solicitation.solicitationNumber;
            const existingSolicitation = solicitationMap[solicitationNumber];

            // If there is no existing solicitation or if the current one has a more recent postedDate
            if ((!existingSolicitation || new Date(solicitation.postedDate) > new Date(existingSolicitation.postedDate)) && archivedSols.includes(solicitation.solicitationNumber) == false) {
                solicitationMap[solicitationNumber] = solicitation;
            }
        });
        
        // Convert the solicitationMap to an array of solicitations
        const trackedSols = Object.values(solicitationMap);

        // Add notes to tracked solicitations, if available
        const notedSols = await addNotesToSolicitations(trackedSols, trackedSolicitations, userID);

        // Return the cleaned solicitations
        return notedSols;
    } catch (error) {
        console.error("Error fetching tracked solicitations:", error);
        return [];
    }
};

// Fetch archived solicitations for the user
export const getArchivedSolicitations = async (archivedSols, userID) => {

  if (archivedSols.length === 0) {
      console.log("No tracked solicitations found.");
      return [];
  }

  // Query for details of archivedSols solicitations in the solicitation collection
  try {
      const solQuery = query(collection(db, 'solicitation'), where('solicitationNumber', 'in', archivedSols));
      const querySnapshot = await getDocs(solQuery);

      const solicitationMap = {};

      querySnapshot.forEach((doc) => {
          let solicitation = doc.data();

          // Format and clean up solicitation data
          if (solicitation.baseType) solicitation.baseType = toProperCaps(solicitation.baseType);
          if (solicitation.contractingOffice) solicitation.contractingOffice = toProperCaps(solicitation.contractingOffice);
          if (solicitation.title) solicitation.title = toProperCaps(solicitation.title);
          if (solicitation.type) solicitation.type = toProperCaps(solicitation.type);
          if (solicitation.description) solicitation.description = toProperCaps(solicitation.description);
          if (solicitation.performanceCity) solicitation.performanceCity = toProperCaps(solicitation.performanceCity);
          if (solicitation.performanceCountry) solicitation.performanceCountry = toProperCaps(solicitation.performanceCountry);
          if (solicitation.performanceStreetAddress) solicitation.performanceStreetAddress = toProperCaps(solicitation.performanceStreetAddress);
          if (solicitation.setAsideDescription) solicitation.setAsideDescription = toProperCaps(solicitation.setAsideDescription);

          solicitation.isSaved = true;

          // Compare the postedDate to keep the most recent solicitation for each solicitationNumber
          const solicitationNumber = solicitation.solicitationNumber;
          solicitationMap[solicitationNumber] = solicitation;
      });
      
      // Convert the solicitationMap to an array of solicitations
      const trackedSols = Object.values(solicitationMap);

      // Add notes to tracked solicitations, if available
      const notedSols = await addNotesToSolicitations(trackedSols, archivedSols, userID);

      // Return the cleaned solicitations
      return notedSols;
  } catch (error) {
      console.error("Error fetching tracked solicitations:", error);
      return [];
  }
};

// Helper function to add notes to solicitations
const addNotesToSolicitations = async (trackedSols, trackedSolicitations, userID) => {
    try {
      const noteQuery = query(
        collection(db, 'note'),
        where('solicitationID', 'in', trackedSolicitations),
        where('userID', '==', userID)
      );
      const noteSnapshot = await getDocs(noteQuery);
  
      // Step 1: Group notes by solicitationID
      const notesBySol = {};
      noteSnapshot.docs.forEach((doc) => {
        const noteData = doc.data();
        const solicitationID = noteData.solicitationID;
  
        if (!notesBySol[solicitationID]) {
          notesBySol[solicitationID] = [];
        }
        notesBySol[solicitationID].push(noteData); // Add the note to the corresponding solicitationID
      });
  
      // Step 2: Add "hasNotes" and "notes" properties to each solicitation in trackedSols
      trackedSols.forEach((sol) => {
        const solicitationNotes = notesBySol[sol.solicitationNumber] || []; // Get notes or empty array
        sol.notes = solicitationNotes;
        sol.hasNotes = solicitationNotes.length > 0; // If there are notes, set hasNotes to true
      });
  
      return trackedSols;
    } catch (error) {
      console.error("Error adding notes to solicitations:", error);
    }
  };

// Utility function to capitalize strings (for formatting)
const toProperCaps = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Utility function to format the response deadline
const formattedRespDeadline = (deadline) => {
    const date = new Date(deadline.seconds * 1000);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};




export const removeFromSavedSearches = async (userID, index) => {
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        const savedSearches = userData.savedSearches || [];
  
        if (index >= 0 && index < savedSearches.length) {
          const searchToRemove = savedSearches[index];
          
          // Remove the search string from savedSearches array
          await updateDoc(userRef, {
            savedSearches: arrayRemove(searchToRemove)
          });
  
          console.log('Search string removed from savedSearches.');
        } else {
          console.log('Invalid index for removing from savedSearches.');
        }
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error removing search string from savedSearches:', error);
    }
  };



  export const removeFromRecentSearches = async (userID, index) => {
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        let recentSearches = userData.recentSearches || [];
  
        if (index >= 0 && index < recentSearches.length) {
          // Remove the search string from recentSearches array
          recentSearches.splice(index, 1);
          
          // Update recentSearches in Firestore
          await updateDoc(userRef, {
            recentSearches: recentSearches
          });
  
          console.log('Search string removed from recentSearches.');
        } else {
          console.log('Invalid index for removing from recentSearches.');
        }
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error removing search string from recentSearches:', error);
    }
  };




  export const stopTrackingSolicitations = async (userID, solicitationNumber) => {
    try {
        
      const userDocRef = doc(db, 'user', userID); // Reference to the user's document
      const userDocSnap = await getDoc(userDocRef); // Get the user document
  
      if (userDocSnap.exists()) {
        // Check if trackedSolicitations array exists in the document
        const userDocData = userDocSnap.data();
        const trackedSolicitations = userDocData.trackedSolicitations || [];
  
        await updateDoc(userDocRef, {
            trackedSolicitations: arrayRemove(solicitationNumber)
          });
        
        console.log(`Successfully updated trackedSolicitations for user: ${userID}`);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error updating trackedSolicitations:', error);
    }
  };