import React, { createContext, useContext, useState } from 'react';

// Create context
const RefreshContext = createContext();

// Custom hook to use the context
export const useRefresh = () => {
  return useContext(RefreshContext);
};

// Provider component
export const RefreshProvider = ({ children }) => {
  const [refreshFunction, setRefreshFunction] = useState(null);

  return (
    <RefreshContext.Provider value={{ refreshFunction, setRefreshFunction }}>
      {children}
    </RefreshContext.Provider>
  );
};