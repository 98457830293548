import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  FormControlLabel,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { SET_ASIDE_MAPS } from '../../utils/constants/SetAsides';

// Clean and split text into keywords
const cleanAndSplit = (text) => {
  return text.replace(/[^a-zA-Z0-9\s]/g, '') // Remove non-alphanumeric characters
    .toLowerCase() // Lowercase
    .split(/\s+/); // Split by spaces
};

const SetAsideAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [checkedSetAsides, setCheckedSetAsides] = React.useState([]);

  // Handle checkbox change
  const handleSetAsideChange = (setAsideDesc) => {
    const cleanedKeywords = cleanAndSplit(setAsideDesc);

    setCheckedSetAsides((prev) =>
      prev.includes(setAsideDesc)
        ? prev.filter((desc) => desc !== setAsideDesc) // Uncheck and remove keywords
        : [...prev, setAsideDesc] // Add to checked
    );

    setKeywords((prev) => {
      // Remove if unchecked
      if (checkedSetAsides.includes(setAsideDesc)) {
        return prev.filter((kw) => !cleanedKeywords.includes(kw));
      }
      // Add cleaned keywords if checked
      return [...prev, ...cleanedKeywords];
    });
  };

  // Uncheck all
  const handleUncheckAll = () => {
    setCheckedSetAsides([]);
    setKeywords((prev) =>
      prev.filter(
        (kw) =>
          !SET_ASIDE_MAPS.flatMap(({ typeDesc }) => cleanAndSplit(typeDesc)).includes(kw)
      )
    );
  };

  // Expose reset functionality to the parent via ref
  useImperativeHandle(ref, () => ({
    resetCheckedSetAsides: () => {
      handleUncheckAll(); // Call the uncheck all method
    }
  }));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }}
      >
        {/* Badge with Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: checkedSetAsides.length === 0 ? 'gray' : '#00274D', // Gray background if 0
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {checkedSetAsides.length}
          </Typography>
        </Box>
        <Typography>Set Aside</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Reset Button */}
        <IconButton onClick={handleUncheckAll} sx={{ mb: 2 }}>
          <RestartAltIcon />
          <Typography variant="caption" sx={{ ml: 0.5, fontSize: '0.75rem', textTransform: 'uppercase' }}>
            Uncheck All
          </Typography>
        </IconButton>

        {/* Scrollable Table with Checkboxes */}
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table size="small">
            <TableBody>
              {SET_ASIDE_MAPS.map((setAside, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSetAsides.includes(setAside.typeDesc)}
                          onChange={() => handleSetAsideChange(setAside.typeDesc)}
                        />
                      }
                      label={setAside.typeDesc}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default SetAsideAccordion;