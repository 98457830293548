import React, { forwardRef, useImperativeHandle } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const TrackingStatusAccordion = forwardRef(({ savedState, setSavedState }, ref) => {
  const handleRadioChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSavedState(selectedValue);  // This will propagate the state change immediately
  };

  const getIcon = () => {
    if (savedState === 0) {
      return <RemoveCircleIcon sx={{ color: 'grey' }} />;
    } else if (savedState === 1) {
      return <CheckCircleIcon sx={{ color: 'green' }} />;
    } else if (savedState === 2) {
      return <CheckCircleIcon sx={{ color: 'grey' }} />;
    }
  };

  // Expose reset functionality to parent component
  useImperativeHandle(ref, () => ({
    resetTrackingStatus: () => {
      setSavedState(0); // Reset to "No Filter" (0)
    }
  }));

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{ width: 24, height: 24, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 }}>
          {getIcon()}
        </Box>
        <Typography>Tracking Status</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup value={savedState.toString()} onChange={handleRadioChange}>
          <FormControlLabel value="0" control={<Radio />} label="No Filter" />
          <FormControlLabel value="1" control={<Radio />} label="Tracked Only" />
          <FormControlLabel value="2" control={<Radio />} label="Not Tracked Only" />
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
});

export default TrackingStatusAccordion;