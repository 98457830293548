import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmm1hpW-2wEv1xVqRB8CK5l8RSEXZblpQ",
  authDomain: "quantum-6c796.firebaseapp.com",
  projectId: "quantum-6c796",
  storageBucket: "quantum-6c796.appspot.com",
  appId: "1:254505298820:web:b9e5fab7ad19248e4e8bd1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Export the app object along with db and auth
export { app, db, auth };