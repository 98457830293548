import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, IconButton, TextField, Button, InputAdornment, Dialog, DialogTitle, DialogActions } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import Sidebar from '../components/sidebar/Sidebar';
import { getSearchResults, addToRecentSearches, addToSavedSearches, updateTrackedSolicitations } from '../networking/SearchCalls';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { green, blue, orange, grey } from '@mui/material/colors';
import { useAuth } from '../context/AuthContext';

const Search = () => {
  const navigate = useNavigate();
  const isInitialLoad = useRef(true);  // Track the first load
  const location = useLocation();  // Access the location object
  const searchName = location.state?.searchName || '';  // Extract searchName from the state
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startAfter, setStartAfter] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [sidebarSearchArray, setSidebarSearchArray] = useState([]);
  const { currentUser } = useAuth();

  // Filter states (set as needed)
  const [savedState, setSavedState] = useState(0);
  const [notesState, setNotesState] = useState(0);
  const [awardState, setAwardState] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSolicitation, setSelectedSolicitation] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Flag to prevent duplicate submissions
  const tableContainerRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Sorting state

  useEffect(() => {
    if (searchName) {
      console.log("Received searchName:", searchName);
      // Split the searchName by spaces and set the sidebarSearchArray
      const searchTerms = searchName.split(/\s+/);  // Splitting by spaces
      navigate(location.pathname, { replace: true, state: null });
      setSidebarSearchArray(searchTerms);
      performSearch(true, sidebarSearchArray);
    }
  }, [searchName]);

  // Open dialog to ask if the user wants to track/untrack
  const handleOpenDialog = (solicitation) => {
    setSelectedSolicitation(solicitation);
    setDialogOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedSolicitation(null);
  };

  // Handle tracking/untracking the solicitation
  const handleTrackUntrack = async () => {
    if (!selectedSolicitation) return;

    const newIsSaved = !selectedSolicitation.isSaved;
    setIsSaving(true); // Disable buttons while saving

    try {
      // Update the tracking status in Firestore
      await updateTrackedSolicitations(currentUser.uid, selectedSolicitation.solicitationNumber, newIsSaved);
      
      // Update the local state to reflect the change
      setSearchResults((prevResults) =>
        prevResults.map((solicitation) =>
          solicitation.solicitationNumber === selectedSolicitation.solicitationNumber
            ? { ...solicitation, isSaved: newIsSaved }
            : solicitation
        )
      );
    } catch (error) {
      console.error("Error updating tracking status:", error);
    } finally {
      setIsSaving(false);
      handleCloseDialog();
    }
  };

  // Perform the search query
  const performSearch = async (newSearch = false, sidebarTerms = []) => {
    const userID = currentUser.uid;

    addToRecentSearches(userID, searchTerm);

    const splitSearchTerm = (input) => {
      const regex = /"([^"]+)"|'([^']+)'|(\S+)/g;
      let matches = [];
      let match;
      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1] || match[2] || match[3]);
      }
      return matches;
    };

    const searchArrayFromSearchBar = splitSearchTerm(searchTerm.toLowerCase().trim());

    const combinedSearchArray = [...sidebarTerms, ...searchArrayFromSearchBar].filter(term => term);

    if (savedState === 0 && notesState === 0 && awardState === 0 && combinedSearchArray.length === 0) {
      return;
    }

    setLoading(true);

    try {
      const result = await getSearchResults(
        userID,
        savedState,
        notesState,
        awardState,
        newSearch ? null : startAfter,
        combinedSearchArray
      );

      if (result.solicitations.length === 0) {
        setShowLoadMore(false);
      }

      if (newSearch) {
        setSearchResults(result.solicitations);
        sessionStorage.setItem('searchResults', JSON.stringify(result.solicitations));
      } else {
        const updatedResults = [...searchResults, ...result.solicitations];
        setSearchResults(updatedResults);
        sessionStorage.setItem('searchResults', JSON.stringify(updatedResults));
      }

      setStartAfter(result.lastVisible || null);

    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  // Clear search input
  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    setShowLoadMore(true);
    sessionStorage.removeItem('searchResults');
    sessionStorage.removeItem('scrollPosition');
  };

  // Save search input
  const handleSaveSearch = () => {
    const userID = currentUser.uid;
    addToSavedSearches(userID, searchTerm);
  };

  // Restore scroll position after the table renders
  const restoreScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (tableContainerRef.current && scrollPosition) {
      tableContainerRef.current.scrollTop = parseInt(scrollPosition, 10);
    }
  };

  // Save scroll position in sessionStorage whenever the user scrolls
  const handleScroll = () => {
    if (tableContainerRef.current) {
      sessionStorage.setItem('scrollPosition', tableContainerRef.current.scrollTop);
    }
  };

  const handleApplyFromSidebar = (saved, notes, award, searchArray) => {
    isInitialLoad.current = false;
    setSavedState(saved); // Save the state passed from the Sidebar
    setNotesState(notes);
    setAwardState(award);
    setSidebarSearchArray(searchArray); // keywords from the Sidebar
  };
  
  const handleClearFromSidebar = () => {
    // Clear out the filter states and reset the search results
    setSavedState(0);
    setNotesState(0);
    setAwardState(0);
    setSidebarSearchArray([]);
    setSearchResults([]); // Clear the search results on reset
    setSearchTerm(''); // Clear search term
  };

// Runs search from APPLY button
//   useEffect(() => {
//     performSearch(true, sidebarSearchArray);
//   }, [savedState, notesState, awardState, sidebarSearchArray]);

// Run only on first load, to restore the cached results if available
//   useEffect(() => {
//     if (isInitialLoad.current && hasCachedResults.current) {
//       isInitialLoad.current = false;  // After the initial check, reset the flag
//       // Restore cached results instead of performing a search
//       return;
//     }
//     isInitialLoad.current = false;  // Reset after the first load
//   }, []);

  // Restore cached search results and scroll position
  useEffect(() => {
    const cachedResults = sessionStorage.getItem('searchResults');
    if (isInitialLoad.current && cachedResults) {
      isInitialLoad.current = false;
      setSearchResults(JSON.parse(cachedResults));
    }
  }, []);

  // Perform search when sidebar state changes, but not on initial load or browser back
  useEffect(() => {
    if (!isInitialLoad.current) {
      performSearch(true, sidebarSearchArray);  // Trigger the search
    }
  }, [savedState, notesState, awardState, sidebarSearchArray]);

//   // Restore cached search results and scroll position
//   useEffect(() => {
//     const cachedResults = sessionStorage.getItem('searchResults');
//     if (cachedResults) {
//       setSearchResults(JSON.parse(cachedResults));
//     }
//   }, []);

  // Restore scroll position after search results update
  useEffect(() => {
    restoreScrollPosition();
  }, [searchResults]);

  // Navigate to the detail page
  const handleNavigateToDetail = (noticeId, solicitation) => {
    const userID = currentUser.uid;
    const email = currentUser.email;
    navigate(`/detail/${noticeId}`, { state: { solicitation: solicitation, userID: userID, email: email } });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSearchResults(sortedSolicitations);
    setSortConfig({ key, direction });
  };

  const sortedSolicitations = [...searchResults].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Convert date strings to Date objects for comparison
      const aDate = aValue ? new Date(aValue) : null;
      const bDate = bValue ? new Date(bValue) : null;

      if (sortConfig.direction === 'asc') {
        if (aDate && bDate) {
          return aDate - bDate; // Sort ascending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      } else {
        if (aDate && bDate) {
          return bDate - aDate; // Sort descending
        } else if (!aDate) {
          return 1; // Move empty/null dates to the end
        } else if (!bDate) {
          return -1;
        }
      }
    }
    return 0;
  });

  const renderSearchBar = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      <TextField
        fullWidth
        label="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            performSearch(true, sidebarSearchArray);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch}>
                <ClearIcon />
              </IconButton>
              <IconButton onClick={() => performSearch(true, sidebarSearchArray)}>
                <SearchIcon />
              </IconButton>
              <IconButton onClick={handleSaveSearch}>
                <SaveIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ width: '100%', maxWidth: '1400px' }}
      />
    </Box>
  );

  const renderTable = () => (
    <Paper elevation={3} sx={{ mt: 2, mb: 2, p: 2, mx: 'auto', display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '1400px', height: 'calc(100vh - 230px)', position: 'relative' }}>
      <Typography variant="h6" gutterBottom>Search Results</Typography>

      <Box
        ref={tableContainerRef}
        sx={{ flexGrow: 1, overflowY: 'auto', opacity: loading ? 0.5 : 1 }}
        onScroll={handleScroll}  // Listen to scroll events and store position
      >
        <Table stickyHeader>
        <TableHead>
            <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell sx={{ minWidth: 80 }}>NAICS</TableCell>
            <TableCell sx={{ minWidth: 100 }}>Status</TableCell>
            <TableCell sx={{ minWidth: 110 }}>Respond By</TableCell>
            <TableCell sx={{ minWidth: 120 }}>
                Posted
                <IconButton onClick={() => handleSort('postedDate')}>
                  {sortConfig.key === 'postedDate' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                Respond By
                <IconButton onClick={() => handleSort('responseDeadline')}>
                  {sortConfig.key === 'responseDeadline' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
            <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {searchResults.map((result, index) => {
              const isAwarded = result.isAwarded || false;
              const isSaved = result.isSaved || false;
              const hasNotes = result.hasNotes || false;
              const type = isAwarded ? "Award" : "Solicitation";
              const rowBackground = isAwarded ? '#f7f7f7' : 'transparent';

              return (
                <TableRow key={index} sx={{ backgroundColor: rowBackground }}>
                  <TableCell sx={{ cursor: 'pointer', color: 'blue', verticalAlign: 'middle' }} onClick={() => handleNavigateToDetail(result.noticeId, result)}>
                    {result.title}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.contractingOffice}
                  </TableCell>
                  <TableCell sx={{ minWidth: 80, verticalAlign: 'middle' }}>
                    {result.naicsCode || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {type}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.responseDeadline}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    {result.postedDate}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'middle' }}>
                    <CheckCircleIcon sx={{ color: !isAwarded ? green[500] : grey[500], verticalAlign: 'middle' }} />
                    <IconButton sx={{ color: isSaved ? blue[500] : grey[500], verticalAlign: 'middle' }} onClick={() => handleOpenDialog(result)}>
                      <VisibilityIcon />
                    </IconButton>
                    <DescriptionIcon sx={{ color: hasNotes ? orange[500] : grey[500], verticalAlign: 'middle' }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      <Sidebar onApply={handleApplyFromSidebar} onClear={handleClearFromSidebar} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'hidden' }}>
        {renderSearchBar()}
        {renderTable()}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" onClick={() => performSearch(false, sidebarSearchArray)}>Load More</Button>
        </Box>

        {/* Dialog for tracking/untracking */}
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedSolicitation?.isSaved
              ? 'Stop tracking this solicitation?'
              : 'Start tracking this solicitation?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleTrackUntrack} disabled={isSaving}>
              Yes
            </Button>
            <Button onClick={handleCloseDialog} disabled={isSaving}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Search;