import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Search from './pages/Search';
import Detail from './pages/Detail';
import CompleteAccount from './pages/CompleteAccount';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { AuthProvider } from './context/AuthContext';  // Import the AuthProvider for authentication context
import { RefreshProvider } from './context/RefreshContext'; // Import the RefreshProvider for managing refresh functions
import PrivateRoute from './components/PrivateRoute';  // Import PrivateRoute for protected routes
import theme from './theme';

// Utility to extract noticeid from the query string
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <RefreshProvider> {/* Wrap everything in RefreshProvider */}
          <Router>
            <Header />
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/complete-account" element={<CompleteAccount />} />

              {/* Private routes */}
              <Route 
                path="/" 
                element={
                  <PrivateRoute>
                    <DashboardWrapper /> {/* Use DashboardWrapper to extract noticeid */}
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/search" 
                element={
                  <PrivateRoute>
                    <Search />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/detail/:id" 
                element={
                  <PrivateRoute>
                    <Detail />
                  </PrivateRoute>
                } 
              />
            </Routes>
          </Router>
        </RefreshProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

// Wrapper component to pass noticeid to Dashboard
function DashboardWrapper() {
  const query = useQuery();
  const noticeid = query.get('noticeid'); // Extract the noticeid from the query string
  
  return <Dashboard noticeid={noticeid} />;
}

export default App;