import React, { useState, useEffect } from 'react';
import { Drawer, List, Box, Button } from '@mui/material';

// Import accordion components
import KeywordAccordion from './KeywordAccordion';
import AgencyAccordion from './AgencyAccordion';
import SetAsideAccordion from './SetAsideAccordion';
import NoticeTypeAccordion from './NoticeTypeAccordion';
import NaicsAccordion from './NaicsAccordion';
import SolNumAccordion from './SolNumAccordion';
import ActiveStatusAccordion from './ActiveStatusAccordion';
import TrackingStatusAccordion from './TrackingStatusAccordion';
import NotesStatusAccordion from './NotesStatusAccordion';

const drawerWidth = 350;

const Sidebar = ({ onApply, onClear }) => {
  const [savedState, setSavedState] = useState(0);  // Saved/Tracked state
  const [notesState, setNotesState] = useState(0);  // Notes state
  const [awardState, setAwardState] = useState(0);  // Award state
  const [keywords, setKeywords] = useState([]);     // Keywords array

  // References to reset functions from each accordion
  const keywordAccordionRef = React.useRef();
  const agencyAccordionRef = React.useRef();
  const setAsideAccordionRef = React.useRef();
  const noticeTypeAccordionRef = React.useRef();
  const naicsAccordionRef = React.useRef();
  const solNumAccordionRef = React.useRef();
  const activeStatusAccordionRef = React.useRef();
  const trackingStatusAccordionRef = React.useRef();
  const notesStatusAccordionRef = React.useRef();

  const handleApply = () => {
    // Pass the latest filter states and keywords array to the parent (Search.js)
    onApply(savedState, notesState, awardState, keywords);
  };

  const handleClear = () => {
    // Reset all filter states
    setSavedState(0);
    setNotesState(0);
    setAwardState(0);
    setKeywords([]);

    // Trigger reset in all accordion components
    if (keywordAccordionRef.current) keywordAccordionRef.current.resetKeywords();
    if (agencyAccordionRef.current) agencyAccordionRef.current.resetSelectedAgencies();
    if (setAsideAccordionRef.current) setAsideAccordionRef.current.resetCheckedSetAsides();
    if (noticeTypeAccordionRef.current) noticeTypeAccordionRef.current.resetCheckedNoticeTypes();
    if (naicsAccordionRef.current) naicsAccordionRef.current.resetCheckedNaics();
    if (solNumAccordionRef.current) solNumAccordionRef.current.resetSolNumList();
    if (activeStatusAccordionRef.current) activeStatusAccordionRef.current.resetActiveStatus();
    if (trackingStatusAccordionRef.current) trackingStatusAccordionRef.current.resetTrackingStatus();
    if (notesStatusAccordionRef.current) notesStatusAccordionRef.current.resetNotesStatus();

    // Notify parent to clear the search
    onClear();
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: '64px',
        },
      }}
    >
      <List>
        {/* Apply and Clear Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Button variant="contained" color="primary" onClick={handleApply}>
            Apply
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClear}>
            Clear
          </Button>
        </Box>

        {/* Accordion components with reset callbacks */}
        <KeywordAccordion ref={keywordAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <AgencyAccordion ref={agencyAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <SetAsideAccordion ref={setAsideAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <NoticeTypeAccordion ref={noticeTypeAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <NaicsAccordion ref={naicsAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <SolNumAccordion ref={solNumAccordionRef} keywords={keywords} setKeywords={setKeywords} />
        <ActiveStatusAccordion ref={activeStatusAccordionRef} awardState={awardState} setAwardState={setAwardState} />
        <TrackingStatusAccordion ref={trackingStatusAccordionRef} savedState={savedState} setSavedState={setSavedState} />
        <NotesStatusAccordion ref={notesStatusAccordionRef} notesState={notesState} setNotesState={setNotesState} />
      </List>
    </Drawer>
  );
};

export default Sidebar;