import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import logo from '../quantum_logo.png'; // Adjust the path if necessary
import asBadge from '../as_badge.png'; // Add the Apple Store badge image
import gpBadge from '../gp_badge.png'; // Add the Google Play badge image
import RightDrawer from './RightDrawer'; // Import the RightDrawer component
import { useRefresh } from '../context/RefreshContext'; // Import useRefresh

const Header = () => {
  const navigate = useNavigate();
  const { refreshFunction } = useRefresh();

  // Call the refresh function
  const connect = () => {
    if (refreshFunction) {
      refreshFunction();  // Call the current page's refresh function
    } else {
      console.log('No refresh function available');
    }
  };

  // Function to open a new browser tab
  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#00274D' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Quantum Logo" style={{ height: '40px', borderRadius: '5px', marginRight: '10px' }} />
            <Typography variant="h6">
              Quantum Solicitation Dashboard
            </Typography>
          </Link>
        </Box>

        {/* App Store Badge Button */}
        <IconButton color="inherit" onClick={() => openInNewTab('https://apps.apple.com/us/app/quantum-crm/id6469711108')} aria-label="apple-store">
          <img src={asBadge} alt="Apple Store" style={{ height: '29px', marginRight: '10px' }} />
        </IconButton>

        {/* Google Play Badge Button */}
        <IconButton color="inherit" onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.veritas.quantum_crm.quantum_crm')} aria-label="google-play-store">
          <img src={gpBadge} alt="Google Play Store" style={{ height: '40px', marginRight: '10px' }} />
        </IconButton>

        {/* Refresh Button */}
        <IconButton color="inherit" onClick={connect} aria-label="refresh">
          <RefreshIcon />
        </IconButton>

        {/* Dashboard Button */}
        <IconButton 
          color="inherit" 
          onClick={() => {
            if (window.location.pathname === '/') {
              window.location.reload(); // Reload if already on the current page
            } else {
              navigate('/'); // Navigate if not on the current page
            }
          }} 
          aria-label="dashboard"
        >
          <DashboardIcon />
        </IconButton>
        {/* <IconButton color="inherit" onClick={() => navigate('/')} aria-label="dashboard">
          <DashboardIcon />
        </IconButton> */}

        {/* Search Button */}
        <IconButton color="inherit" onClick={() => navigate('/search')} aria-label="search">
          <SearchIcon />
        </IconButton>

        {/* Right Drawer Button */}
        <RightDrawer />
      </Toolbar>
    </AppBar>
  );
};

export default Header;