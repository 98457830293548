import { getFunctions, httpsCallable } from 'firebase/functions';  // Import the necessary functions
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db, app } from '../firebase';  // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);  // Initialize Firebase Functions with the app

// This function will call the getSolDashSearchResults cloud function
export const getSearchResults = async (userID, savedState, notesState, awardState, startAfter, searchArray) => {
    console.log(JSON.stringify(searchArray));
  try {
    // Prepare the callable cloud function using httpsCallable
    const getSolDashSearchResults = httpsCallable(functions, 'getSolDashSearchResults');  // Note the usage of httpsCallable

    // Prepare the data to be sent to the cloud function
    const data = {
      userID: userID,           // User ID
      savedState: savedState,   // Saved state filter (true/false)
      notesState: notesState,   // Notes state filter (true/false)
      awardState: awardState,   // Award state filter (true/false)
      startAfter: startAfter,   // Pagination: reference to the last document (null if no pagination)
      searchArray: searchArray  // Array of search terms (strings)
    };

    // Call the cloud function with the prepared data
    const result = await getSolDashSearchResults(data);

    // The cloud function returns a data payload
    return result.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;  // Optionally throw the error for further handling
  }
};




// Define a function to call the getUsaOfficesForAgency cloud function
export const getUsaOfficesForAgency = async (agency) => {
    // Reference to the cloud function
  
    // console.log("dashCalls keywords: " + JSON.stringify(keywords));
    // console.log("dashCalls awardType: " + awardType);
  
    const getOfficesForAgency = httpsCallable(functions, 'getUsaOfficesForAgency');
    
    try {
      // Call the cloud function with the required data
      const result = await getOfficesForAgency({ agency });
  
      console.log("result.data.offices: " + result.data.offices.length);
      
      return result.data.offices; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };




  export const realtimeSearchOfficesForAgency = async (searchString) => {
    try {
      const officesRef = collection(db, 'officesForAgency');
      const q = query(officesRef, where('searchableName', 'array-contains', searchString.toLowerCase()));
      const querySnapshot = await getDocs(q);
      
      const offices = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      return offices;
    } catch (error) {
      console.error('Error fetching offices: ', error);
      return [];
    }
  };



  export const addToSavedSearches = async (userID, searchString) => {
    try {
      const userRef = doc(db, 'user', userID);
      await updateDoc(userRef, {
        savedSearches: arrayUnion(searchString) // Add search string to the array
      });
      console.log('Search string added to savedSearches.');
    } catch (error) {
      console.error('Error adding search string to savedSearches:', error);
    }
  };




  export const addToRecentSearches = async (userID, searchString) => {
    if (!searchString.trim()) {
      console.log('Empty search string, nothing to add.');
      return;
    }
  
    try {
      const userRef = doc(db, 'user', userID);
      const userSnap = await getDoc(userRef);
      
      if (userSnap.exists()) {
        const userData = userSnap.data();
        let recentSearches = userData.recentSearches || [];
  
        // Add search string to the beginning of the array
        recentSearches.unshift(searchString);
  
        // Ensure recentSearches doesn't exceed 10 items
        if (recentSearches.length > 10) {
          recentSearches = recentSearches.slice(0, 10);
        }
  
        // Update recentSearches in Firestore
        await updateDoc(userRef, {
          recentSearches: recentSearches
        });
        console.log('Search string added to recentSearches.');
      } else {
        console.log('User document does not exist.');
      }
    } catch (error) {
      console.error('Error adding search string to recentSearches:', error);
    }
  };





  export const updateTrackedSolicitations = async (userID, solicitationNumber, isSave) => {
    try {
        
      const userDocRef = doc(db, 'user', userID); // Reference to the user's document
      const userDocSnap = await getDoc(userDocRef); // Get the user document
  
      if (userDocSnap.exists()) {
        // Check if trackedSolicitations array exists in the document
        const userDocData = userDocSnap.data();
        const trackedSolicitations = userDocData.trackedSolicitations || [];
  
        // Update trackedSolicitations based on isSave value
        if (isSave) {
          // Add noticeId to trackedSolicitations if it's not already there
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayUnion(solicitationNumber)
          });
        } else {
          // Remove noticeId from trackedSolicitations if it exists
          await updateDoc(userDocRef, {
            trackedSolicitations: arrayRemove(solicitationNumber)
          });
        }
        
        console.log(`Successfully updated trackedSolicitations for user: ${userID}`);
      } else {
        console.error('User document does not exist.');
      }
    } catch (error) {
      console.error('Error updating trackedSolicitations:', error);
    }
  };