import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Paper, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signup } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password);
      navigate('/');
    } catch (error) {
      console.error("Failed to sign up", error);
    }
  };

  const handleSignIn = () => {
    navigate('/login'); // Navigate to the login page
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#00274D' }}> {/* Set the background color of the avatar */}
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="#00274D"> {/* Set the text color */}
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: '#00274D' } }} // Label color
              InputProps={{
                style: { color: '#00274D' }, // Text color
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: '#00274D' } }} // Label color
              InputProps={{
                style: { color: '#00274D' }, // Text color
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: '#00274D', color: '#fff' }} // Button background color and text color
            >
              Sign Up
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleSignIn}
              sx={{
                color: '#00274D',
                borderColor: '#00274D', // Outline color
                '&:hover': {
                  backgroundColor: '#00274D',
                  color: '#fff',
                },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Signup;