export const SET_ASIDE_MAPS = [
    {
      typeCode: "0",
      typeDesc: "No Set-Aside Used"
    },
    {
      typeCode: "sba",
      typeDesc: "Small Business — Total"
    },
    {
      typeCode: "8a",
      typeDesc: "8(a) Competed"
    },
    {
      typeCode: "hmt",
      typeDesc: "Historically Underutilized Business Zone (HUBZone) — Total"
    },
    {
      typeCode: "hmp",
      typeDesc: "Historically Underutilized Business Zone (HUBZone) — Partial"
    },
    {
      typeCode: "vsb",
      typeDesc: "Very Small Business"
    },
    {
      typeCode: "esb",
      typeDesc: "Emerging Small Business"
    },
    {
      typeCode: "hzc",
      typeDesc: "HUBZone"
    },
    {
      typeCode: "sdvosb",
      typeDesc: "Service-Disabled Veteran-Owned Small Business"
    },
    {
      typeCode: "bi",
      typeDesc: "Buy Indian"
    },
    {
      typeCode: "sdvobs",
      typeDesc: "Service-Disabled Veteran-Owned Small Business Sole Source"
    },
    {
      typeCode: "8an",
      typeDesc: "8(a) Sole Source"
    },
    {
      typeCode: "rsb",
      typeDesc: "Reserved for Small Business"
    },
    {
      typeCode: "8ac(civ)",
      typeDesc: "Small Disadvantaged Business Set-Aside"
    },
    {
      typeCode: "hs2(civ)",
      typeDesc: "Combination HUBZone and 8(a)"
    },
    {
      typeCode: "hs3",
      typeDesc: "Small Disadvantaged Business, 8(a), and HUBZone"
    },
    {
      typeCode: "vsa",
      typeDesc: "Veteran-Owned Small Business"
    },
    {
      typeCode: "vss",
      typeDesc: "Veteran-Owned Small Business Sole Source"
    },
    {
      typeCode: "wosb",
      typeDesc: "Woman-Owned Small Business"
    },
    {
      typeCode: "edwosb",
      typeDesc: "Economically Disadvantaged Woman-Owned Small Business"
    },
    {
      typeCode: "anc",
      typeDesc: "Alaska Native Corporation (ANC)"
    },
    {
      typeCode: "nc",
      typeDesc: "Native Corporation"
    },
    {
      typeCode: "msc",
      typeDesc: "Minority Small Business Concern"
    },
    {
      typeCode: "mp",
      typeDesc: "Mentor-Protégé Program"
    },
    {
      typeCode: "ai",
      typeDesc: "American Indian"
    },
    {
      typeCode: "oos",
      typeDesc: "Other Small Business"
    },
    {
      typeCode: "8jd",
      typeDesc: "8(a) Joint Venture"
    },
    {
      typeCode: "rsb(a)",
      typeDesc: "Reserved for Small Business (8(a))"
    },
    {
      typeCode: "wo8a",
      typeDesc: "Woman-Owned 8(a)"
    }
  ];