import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const SolNumAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [solNum, setSolNum] = useState(''); // Single input for solicitation number
  const [solNumList, setSolNumList] = useState([]); // List of added solicitation numbers

  // Add keywords by splitting and lowercasing
  const addKeywords = (text) => {
    const splitKeywords = text.toLowerCase().split(/\s+/);
    setKeywords((prev) => [...prev, ...splitKeywords]);
  };

  // Handle adding solicitation number to the list
  const handleAddSolNum = () => {
    if (solNum.trim() !== '') {
      const cleanedSolNum = solNum.trim().toLowerCase();
      setSolNumList((prev) => [...prev, cleanedSolNum]);
      addKeywords(cleanedSolNum); // Add the cleaned solicitation number to keywords
      setSolNum(''); // Clear the input field
    }
  };

  // Handle clearing the input field
  const handleClearInput = () => {
    setSolNum('');
  };

  // Handle removing a solicitation number from the list and keywords
  const handleRemoveSolNum = (removeItem) => {
    setSolNumList((prev) => prev.filter((item) => item !== removeItem));
    setKeywords((prev) => prev.filter((kw) => !removeItem.includes(kw)));
  };

  // Expose reset functionality to parent component
  useImperativeHandle(ref, () => ({
    resetSolNumList: () => {
      setSolNumList([]); // Clear all solicitation numbers
      setKeywords((prev) => prev.filter((kw) => !solNumList.includes(kw))); // Clear related keywords
    }
  }));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }} // Light gray background for consistency
      >
        {/* Circle badge showing number of added solicitation numbers */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: solNumList.length === 0 ? 'gray' : '#00274D', // Gray if 0, navy blue otherwise
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {solNumList.length}
          </Typography>
        </Box>
        <Typography>Solicitation Number</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {/* Input Field */}
          <TextField
            fullWidth
            label="Enter solicitation number"
            value={solNum}
            onChange={(e) => setSolNum(e.target.value)}
          />

          {/* Clear Input Button */}
          <IconButton onClick={handleClearInput} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>

          {/* Add Button */}
          <IconButton onClick={handleAddSolNum} sx={{ ml: 1 }}>
            <AddIcon />
          </IconButton>
        </Box>

        {/* List of added solicitation numbers */}
        <List>
          {solNumList.map((sol, index) => (
            <ListItem key={index}>
              <ListItemText primary={sol} />
              <ListItemSecondaryAction>
                {/* Remove Button */}
                <IconButton edge="end" onClick={() => handleRemoveSolNum(sol)}>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
});

export default SolNumAccordion;