import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  FormControlLabel,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { NOTICE_TYPES } from '../../utils/constants/NoticeTypes'; // Import NOTICE_TYPES

// Helper function to capitalize the first letter of each word
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

// NoticeTypeAccordion Component
const NoticeTypeAccordion = forwardRef(({ keywords, setKeywords }, ref) => {
  const [checkedNoticeTypes, setCheckedNoticeTypes] = React.useState([]);

  // Handle checkbox change
  const handleNoticeTypeChange = (noticeType) => {
    setCheckedNoticeTypes((prev) =>
      prev.includes(noticeType)
        ? prev.filter((type) => type !== noticeType) // Remove unchecked
        : [...prev, noticeType] // Add checked
    );

    setKeywords((prev) => {
      // If unchecked, remove from keywords
      if (checkedNoticeTypes.includes(noticeType)) {
        return prev.filter((kw) => kw !== noticeType);
      }
      // If checked, add to keywords
      return [...prev, noticeType];
    });
  };

  // Uncheck all and clear the keywords related to Notice Types
  const handleUncheckAll = () => {
    setCheckedNoticeTypes([]);
    setKeywords((prev) => prev.filter((kw) => !NOTICE_TYPES.includes(kw)));
  };

  // Expose reset functionality to the parent via ref
  useImperativeHandle(ref, () => ({
    resetCheckedNoticeTypes: () => {
      handleUncheckAll(); // Call the uncheck all method
    }
  }));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#f5f5f5' }}
      >
        {/* Badge with Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: checkedNoticeTypes.length === 0 ? 'gray' : '#00274D', // Gray if count is 0
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {checkedNoticeTypes.length}
          </Typography>
        </Box>
        <Typography>Notice Type</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Reset Button */}
        <IconButton onClick={handleUncheckAll} sx={{ mb: 2 }}>
          <RestartAltIcon />
          <Typography variant="caption" sx={{ ml: 0.5, fontSize: '0.75rem', textTransform: 'uppercase' }}>
            Uncheck All
          </Typography>
        </IconButton>

        {/* Scrollable Table with Checkboxes */}
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table size="small">
            <TableBody>
              {NOTICE_TYPES.map((noticeType, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedNoticeTypes.includes(noticeType)}
                          onChange={() => handleNoticeTypeChange(noticeType)}
                        />
                      }
                      label={capitalizeWords(noticeType)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default NoticeTypeAccordion;