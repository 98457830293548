import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, TextField, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import { getSolDetail, processSingleResourceURL, postNote, deleteNote, updateTrackedSolicitations, updateArchivedSols } from '../networking/DetailCalls';
import { processStringFromHTML } from '../utils/Helpers';

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Detail = () => {
  const location = useLocation();
  const initialSolicitation = location.state?.solicitation;
  const userID = location.state?.userID;
  const email = location.state?.email;
  const [solicitation, setSolicitation] = useState(initialSolicitation); 
  const [solDetail, setSolDetail] = useState({ amendments: [], award: null });
  const [notes, setNotes] = useState([]);
  const [noteInput, setNoteInput] = useState('');
  const [storedResources, setStoredResources] = useState(initialSolicitation.storedResources || []);
  const [processingProgress, setProcessingProgress] = useState(null);
  const [isArchived, setIsArchived] = useState(false); // For archive/unarchive button state
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  useEffect(() => {
    const isAwarded = solicitation.isAwarded ? true : false;
    console.log("solicitation.hasNotes: " + solicitation.hasNotes + " -- " + notes.length);

    setNotes(solicitation.notes);

    // Check if the solicitation is already archived
    if (solicitation.archivedSols?.includes(solicitation.solicitationNumber)) {
      setIsArchived(true);
    }

    if (solicitation.resourceLinks && solicitation.resourceLinks.length > 0) {
      handleResourceProcessing(solicitation.noticeId, solicitation.storedResources || [], solicitation.resourceLinks);
    }

    getSolDetail(solicitation.noticeId, solicitation.solicitationNumber, isAwarded)
      .then((data) => {
        setSolDetail(data);
      })
      .catch((error) => {
        console.error("Error fetching solicitation detail:", error);
      });
  }, [solicitation]);

  const handleResourceProcessing = (noticeId, stored, links) => {
    setStoredResources(stored);

    if (stored.length < links.length) {
      setProcessingProgress(`Processing: ${stored.length}/${links.length}`);

      const missingIndexes = [...Array(links.length).keys()].slice(stored.length);
      missingIndexes.forEach((index) => {
        processSingleResourceURL(noticeId, links[index], index)
          .then((resource) => {
            setStoredResources((prevResources) => {
              const updatedResources = [...prevResources, resource].slice(0, links.length);
              if (updatedResources.length === links.length) {
                setProcessingProgress(null); // Processing complete
              } else {
                setProcessingProgress(`Processing: ${updatedResources.length}/${links.length}`);
              }
              return updatedResources;
            });
          })
          .catch((error) => console.error("Error processing resource URL:", error));
      });
    }
  };

  const handleAddNote = () => {
    if (noteInput.trim() !== '') {
      const newNote = noteInput.trim();
      const solicitationId = solicitation.solicitationNumber;

      postNote(userID, solicitationId, newNote)
        .then((noteData) => {
          setNotes((prevNotes) => [...prevNotes, noteData]);
          setNoteInput('');
        })
        .catch((error) => console.error('Error adding note:', error));
    }
  };

  const handleDeleteNote = (noteId) => {
    deleteNote(noteId)
      .then(() => {
        setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
      })
      .catch((error) => console.error('Error deleting note:', error));
  };

  const handleTrackUntrack = () => {
    const updatedIsSaved = !solicitation.isSaved;
    setSolicitation((prevSolicitation) => ({
      ...prevSolicitation,
      isSaved: updatedIsSaved,
    }));

    updateTrackedSolicitations(userID, solicitation.solicitationNumber, updatedIsSaved)
      .then(() => {
        console.log(
          updatedIsSaved
            ? `Solicitation ${solicitation.solicitationNumber} is now tracked.`
            : `Solicitation ${solicitation.solicitationNumber} is no longer tracked.`
        );
      })
      .catch((error) => {
        console.error('Error updating trackedSolicitations:', error);
      });
  };

  const handleShare = () => {
    const subject = "Solicitation Shared from Quantum CRM";
    const body = `Solicitation: ${solicitation.title}\n\nFrom: ${email}\n\nhttps://app.quantum-crm.com/?noticeid=${solicitation.noticeId}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  // Function to handle sharing the file
  const handleShareFile = (fileUrl) => {
    // Try using the native share API if available
    if (navigator.share) {
      navigator.share({
        title: 'Check out this file',
        url: fileUrl,
      }).catch((error) => console.error("Error sharing file:", error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  // Function to handle downloading the file
  const handleDownloadFile = (fileUrl, fileName) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName); // Download attribute to specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // Function to handle archive/unarchive action
  const handleArchiveUnarchive = () => {
    setOpenArchiveDialog(true); // Open the confirmation dialog
  };

  // Function to confirm archive action
  const confirmArchiveAction = () => {
    const newIsArchived = !isArchived; // Toggle archived state
    updateArchivedSols(userID, solicitation.solicitationNumber, newIsArchived)
      .then(() => {
        setIsArchived(newIsArchived);
        console.log(
          newIsArchived
            ? `Solicitation ${solicitation.solicitationNumber} is now archived.`
            : `Solicitation ${solicitation.solicitationNumber} is no longer archived.`
        );
        setOpenArchiveDialog(false); // Close dialog after action
      })
      .catch((error) => {
        console.error('Error updating archivedSols:', error);
        setOpenArchiveDialog(false); // Close dialog in case of error
      });
  };
  // const handleArchiveUnarchive = () => {
  //   const newIsArchived = !isArchived; // Toggle archived state
  //   updateArchivedSols(userID, solicitation.solicitationNumber, newIsArchived)
  //     .then(() => {
  //       setIsArchived(newIsArchived);
  //       console.log(
  //         newIsArchived
  //           ? `Solicitation ${solicitation.solicitationNumber} is now archived.`
  //           : `Solicitation ${solicitation.solicitationNumber} is no longer archived.`
  //       );
  //     })
  //     .catch((error) => {
  //       console.error('Error updating archivedSols:', error);
  //     });
  // };

  const handleAmendmentSelect = (amendment) => {
    // console.log("handleAmendmentSelect: " + amendment.noticeId);
    getSolDetail(amendment.noticeId, amendment.solicitationNumber, amendment.isAwarded)
      .then((newSolDetail) => {

        setSolicitation({
          ...amendment,
          notes: solicitation.notes,
          hasNotes: solicitation.hasNotes // Keep the original solicitation's notes state
        });
  
        // Only update solDetail and stored resources, not notes
        setSolDetail(newSolDetail);
        setStoredResources(amendment.storedResources || []);
      })
      .catch((error) => {
        console.error("Error fetching amendment detail:", error);
      });
  };

  const handleOpenFile = (fileUrl, fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    if (fileExtension === 'csv' || fileExtension === 'xlsx') {
      // Try to open in Google Sheets
      const googleSheetsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
      window.open(googleSheetsUrl, '_blank');
    } else if (fileExtension === 'docx') {
      // Open in Google Drive viewer
      const googleDriveUrl = `https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
      window.open(googleDriveUrl, '_blank');
    } else if (fileExtension === 'pdf') {
      // Use Google Docs Viewer for PDFs to ensure it opens in the browser
      const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
      window.open(googleDocsUrl, '_blank');
    } else {
      // Default behavior for unsupported formats
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>{solicitation.title}</Typography>
  
      {/* Action Buttons */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button 
          variant="contained" color="success"
          onClick={handleTrackUntrack}
        >
          {solicitation.isSaved ? 'Currently Tracking' : 'Track Solicitation'}
        </Button>
  
        <Button variant="contained" color="primary" onClick={handleShare}>
          Share
        </Button>
  
        {solicitation.uiLink && (
          <Button 
            variant="contained" 
            sx={{ backgroundColor: 'grey', color: 'white' }} 
            onClick={() => window.open(solicitation.uiLink, '_blank')}
          >
            SAM.gov
          </Button>
        )}
  
        <Button 
          variant="contained" 
          sx={{ backgroundColor: 'red', color: 'white', marginLeft: 'auto' }} 
          onClick={handleArchiveUnarchive}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </Box>
  
      {/* Confirmation Dialog */}
      <Dialog open={openArchiveDialog} onClose={() => setOpenArchiveDialog(false)}>
        <DialogTitle>Confirm Archive Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {isArchived ? 'unarchive' : 'archive'} this solicitation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenArchiveDialog(false)} color="primary">Cancel</Button>
          <Button onClick={confirmArchiveAction} color="primary" autoFocus>Yes, {isArchived ? 'Unarchive' : 'Archive'}</Button>
        </DialogActions>
      </Dialog>
  
      {/* Grid Layout */}
      <Grid container spacing={3} sx={{ marginBottom: 3 }}>
        {/* Solicitation Info Table (2/3rd width) */}
        <Grid item xs={8}>
          <Paper sx={{ padding: 2, height: '400px', display: 'flex', flexDirection: 'column' }}>
            <Table sx={{ flexGrow: 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Solicitation Number</TableCell>
                  <TableCell>Contracting Office</TableCell>
                  <TableCell>PoP</TableCell>  
                  <TableCell>Type</TableCell>
                  <TableCell>Solicitation Date</TableCell>
                  <TableCell>Set Aside Description</TableCell>
                  <TableCell>NAICS Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{solicitation.solicitationNumber}</TableCell>
                  <TableCell>
                    {solicitation.contractingOffice.split('.').map((line, index) => (
                      <span key={index}>{line}<br /></span>
                    ))}
                  </TableCell>
                  <TableCell>
                    {solicitation.performanceCity ? capitalizeWords(solicitation.performanceCity) : 'N/A'}, {solicitation.performanceCountry?.toUpperCase() || 'N/A'} {solicitation.performanceZipCode || ''}
                  </TableCell>
                  <TableCell>
                    {solicitation.isAwarded ? 'Award' : solicitation.baseType}
                  </TableCell>
                  <TableCell>{solicitation.postedDate}</TableCell>
                  <TableCell>{solicitation.setAsideDescription}</TableCell>
                  <TableCell>{solicitation.naicsCode}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        
        {/* Description Section (1/3rd width) */}
        <Grid item xs={4}>
          <Paper sx={{ padding: 2, height: '400px', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>Description</Typography>
            <Typography>
              {solicitation.descriptionText && solicitation.descriptionText.trim() !== '' ? 
                processStringFromHTML(solicitation.descriptionText) : 
                'No description'}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
  
      {/* Horizontally aligned Notes, Additional Resources, and Amendments */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
        {/* Notes Section */}
        <Paper sx={{ width: '32%', padding: 2, minHeight: '200px', maxHeight: '400px', overflowY: 'auto' }}>
          <Typography variant="h6" gutterBottom>Notes</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <TextField
              fullWidth
              label="Note to add..."
              value={noteInput}
              onChange={(e) => setNoteInput(e.target.value)}
            />
            <IconButton onClick={handleAddNote} sx={{ marginLeft: 1 }}>
              <AddIcon />
            </IconButton>
          </Box>
          <Table size="small">
            <TableBody>
              {notes.map((note, index) => (
                <TableRow key={index}>
                  <TableCell>{note.note}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDeleteNote(note.id)}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
  
        {/* Additional Resources */}
        <Paper sx={{ width: '32%', padding: 2, minHeight: '200px', maxHeight: '400px', overflowY: 'auto' }}>
          <Typography variant="h6" gutterBottom>Additional Resources</Typography>
          {solicitation.resourceLinks?.length > 0 ? (
            <Box>
              {processingProgress && (
                <Typography variant="body2">{processingProgress}</Typography>
              )}
              <Table size="small">
                <TableBody>
                  {storedResources.map((resource, index) => (
                    <TableRow key={index}>
                      {/* File name with buttons below it */}
                      <TableCell>
                        <Box>
                          <Typography>File: {resource.readableName}</Typography>
                          <Box sx={{ display: 'flex', gap: 1, mt: 0 }}>
                            <IconButton onClick={() => handleOpenFile(resource.url, resource.readableName)} sx={{ color: 'blue' }}>
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton onClick={() => handleShareFile(resource.url)} sx={{ color: 'green' }}>
                              <ShareIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDownloadFile(resource.url, resource.readableName)} sx={{ color: 'orange' }}>
                              <DownloadIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Typography>N/A</Typography>
          )}
        </Paper>
  
        {/* Amendments */}
        <Paper sx={{ width: '32%', padding: 2, minHeight: '200px', maxHeight: '400px', overflowY: 'auto' }}>
          <Typography variant="h6" gutterBottom>Amendments</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Solicitation Date</TableCell>
                <TableCell>Posted Files</TableCell>
                <TableCell>Response Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {solDetail.amendments?.map((amendment, index) => (
                <TableRow 
                  key={index} 
                  onClick={() => handleAmendmentSelect(amendment)} 
                  style={{ cursor: 'pointer', backgroundColor: '#f5f5f5' }}  
                >
                  <TableCell>{amendment.postedDate}</TableCell>
                  <TableCell>{amendment.resourceLinks?.length || 0}</TableCell>
                  <TableCell>{amendment.responseDeadline || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

export default Detail;