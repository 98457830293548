import React from 'react';

export const processStringFromHTML = (inputString) => {
    // Regular expression to check if the string contains HTML tags
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    // Check if the string matches the HTML pattern
    if (!htmlRegex.test(inputString)) {
        // If the string is not HTML, return it as it is
        return inputString;
    }

    // Create a temporary DOM element to convert HTML to plain text
    const tempElement = document.createElement('div');
    tempElement.innerHTML = inputString;

    // Replace <br> with newlines and then split by newline to preserve line breaks
    const plainText = tempElement.textContent || tempElement.innerText || "";
    const formattedText = plainText.replace(/<br\s*\/?>/gi, '\n');

    // Return an array that replaces newlines with <br /> for React
    return formattedText.split('\n').map((line, index) => (
        <React.Fragment key={index}>
        {line}
        <br />
        </React.Fragment>
    ));
};