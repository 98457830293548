import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged
import { queryTempStripeUser, updateUserAccount, createUserDocument } from '../networking/AccountCalls';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Paper, Avatar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



    // 
    // 
    // 
    // ?status=success
    // 
    // 
    //


const CompleteAccount = () => {
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signup, login, currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const auth = getAuth();  // Get the auth instance
  
    try {
      let isSignUp = false;
  
      // Step 1: Try logging in the user
      try {
        await login(email, password);  // Attempt login first
      } catch (loginError) {
        console.log('Login failed, attempting signup...');
  
        // Step 2: If login fails, attempt to sign up the user
        try {
          await signup(email, password); // Signup if login fails
          isSignUp = true;
        } catch (signupError) {
          console.error('Signup failed:', signupError);
          alert('Signup failed. Please check your details and try again.');
          return;
        }
      }
  
      // Step 3: Wait for the user state to be updated
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid; // Get the user UID from the updated user state
  
          // Step 4: Query tempStripeUser after login or signup
          const tempStripeUserData = await queryTempStripeUser(email);
  
          if (!tempStripeUserData) {
            alert('No subscription data found. Please sign up for a subscription.');
            return;
          }
  
          // Step 5: If the user logged in, update the existing user document
          if (!isSignUp) {
            console.log("logged in");
            const userUpdated = await updateUserAccount(email, tempStripeUserData);
            if (userUpdated) {
              navigate('/'); // If user is updated, navigate to home
              return;
            }
          }
  
          // Step 6: If the user signed up, create a new user document
          if (isSignUp) {
            const success = await createUserDocument(uid, email, company, tempStripeUserData);
            if (success) {
              navigate('/'); // If user document creation is successful, navigate to home
            } else {
              console.error('Failed to create user document.');
            }
          }
        } else {
          console.error('Failed to retrieve user ID.');
        }
      });
  
    } catch (error) {
      console.error("Error during account setup process", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#00274D' }}> {/* Set the background color of the avatar */}
            <CheckCircleOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="#00274D"> {/* Set the text color */}
            Complete Your Account Setup
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="company"
              label="Company"
              name="company"
              autoComplete="company"
              autoFocus
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              InputLabelProps={{ style: { color: '#00274D' } }} // Label color
              InputProps={{
                style: { color: '#00274D' }, // Text color
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: '#00274D' } }} // Label color
              InputProps={{
                style: { color: '#00274D' }, // Text color
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: '#00274D' } }} // Label color
              InputProps={{
                style: { color: '#00274D' }, // Text color
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: '#00274D', color: '#fff' }} // Button background color and text color
            >
              Finish Account Setup
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default CompleteAccount;